import { createElement, FC, useCallback, useMemo } from 'react';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import { FormRendererInfoContext, useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { AdHocAnswerResponse } from '../../../../models/Form';
import { FormActionContext, useFormAction } from '../../../../contexts/FormActionContext';
import ObjectUtils from '../../../../utils/ObjectUtils';
import AdHocActionWrapper from './AdHocActionWrapper';
import ActionTypes from '../../ActionTypes';
import { useFormSectionRendererInfo } from '../../../../contexts/FormSectionRendererContext';

const AdHocFieldsPreview: FC<ActionPreviewBaseProps<AdHocAnswerResponse, unknown>> = (props) => {
  const { answerOnly, response } = props;
  const { adHocAnswers } = useFormRendererInfo();
  const { currentAction, createPlaceholder, deletePlaceholder } = useFormAction(props);
  const { step: currentStep } = useFormSectionRendererInfo();

  const responses = useMemo(() => {
    return adHocAnswers[currentAction.id] ?? [];
  }, [adHocAnswers, currentAction.id]);

  const fieldDataTransformer = useCallback(
    (field: AdHocAnswerResponse) => {
      let newData = ObjectUtils.DeepClone(currentAction).data;
      // field.question is question data, not answer data
      newData = { ...newData, ...field.question };
      // Fix title/question mismatch
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newData.question = (newData as any).title ?? newData.question ?? '';

      return newData;
    },
    [currentAction],
  );

  if (answerOnly) {
    return <>{response || '-'}</>;
  }

  return (
    <>
      <FormRendererInfoContext.Consumer>
        {(frInfo) => (
          <FormRendererInfoContext.Provider value={frInfo}>
            {responses.map((field, i) => (
              <FormActionContext.Provider
                key={field.fieldId}
                value={{
                  currentSection: currentStep,
                  currentAction: {
                    ...field,
                    id: currentAction.id,
                    type: field.data.type,
                    visible: 'true',
                    data: fieldDataTransformer(field),
                    number: field.number,
                  },
                  actionValid: true,
                  riskLevel: 0, // TODO
                  readOnly: true,
                  busySavingAction: false,
                  onAnswer: () => Promise.resolve(),
                  onValid: () => ({}),
                  createPlaceholder,
                  deletePlaceholder,
                }}
              >
                <AdHocActionWrapper isLast={i === responses.length - 1} readonly>
                  {createElement(ActionTypes[field.question.type].previewAction, {
                    id: currentAction.id,
                    data: fieldDataTransformer(field),
                    response: field.data,
                    required: false,
                  })}
                </AdHocActionWrapper>
              </FormActionContext.Provider>
            ))}
          </FormRendererInfoContext.Provider>
        )}
      </FormRendererInfoContext.Consumer>
    </>
  );
};

export default AdHocFieldsPreview;
